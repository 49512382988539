/**
 * performance
 * 
 */

import request from '@/utils/request'

// 编辑
export const performanceRoleUpdate = (data) => {
    return request({
        method: 'post',
        url: '/Performance/role-update',
        data
    })
}
// 
export const performanceRoleIntent = (data) => {
    return request({
        method: 'post',
        url: '/Performance/role-intent',
        data
    })
}
// 展示
export const performanceFindlist = (data) => {
    return request({
        method: 'post',
        url: '/Performance/findlist',
        data
    })
}
// 添加用户
export const performanceUserAdd = (data) => {
    return request({
        method: 'post',
        url: '/Performance/user-add',
        data
    })
}